<template>
  <!-- Desc: View: product & category targeting for - Prouct targeting adgroups  -->
  <div
    class="adGroupsKeywordTargetingProduct card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <productTargeting
      v-if="showAddCustomProductPanel"
      :widget-configs="tabConfig.customActionPanelConfigs"
      :context="context"
      :hide-panel="tabConfig.hideCustomActionPanel"
      :campaign-type="$route.params.entityType || 'sp'"
      default-tab="Products"
    />
    <component
      :is="audienceActionPanel.component"
      v-if="showAddAudiences"
      :key="audienceTargetingKey"
      class="add-keyword-campaign-creation u-spacing-pv-m"
      v-bind="audienceActionPanel.props"
      v-on="audienceActionPanel.events"
    >
      <div
        v-if="defaultTabIndex !== 0"
        slot="aboveLeftCustomBid"
        class="u-display-flex u-spacing-ph-l u-spacing-pb-m u-flex-align-items-center"
      >
        <div class="u-spacing-pr-s u-font-weight-600 u-font-size-5">
          Lookback:
        </div>
        <rb-select
          :options="lookBack"
          :send-details="true"
          class="u-width-160px u-spacing-mr-xs height-custom-bid u-border-all u-border-width-s u-border-color-grey-xxx-light u-flex-align-items-center u-flex-justify-content-center u-border-radius-s u-display-flex u-flex-align-items-center"
          :on-close="onSelectLookBack"
        >
          <div
            slot="trigger"
            class="u-display-flex u-cursor-pointer u-flex-justify-content-space-between"
          >
            <div
              class="u-width-100 u-flex-align-items-center u-display-flex u-flex-justify-content-space-between"
            >
              <div class="u-font-size-6 u-spacing-pl-s">
                {{ selectedLookBack ? `${selectedLookBack} days` : 'NA' }}
              </div>
              <rb-icon
                class="rb-icon--medium u-spacing-ml-xs u-color-grey-lighter"
                :icon="'caret-down'"
              />
            </div>
          </div>
        </rb-select>
      </div>
      <div slot="leftTableHeader">
        <GenericTabsComponent
          :tabs-list="tabList"
          :default-tab-index="defaultTabIndex"
          @handleTabsClick="handleTabsClick"
        />
      </div>
    </component>
    <div v-if="adjustBids">
      <div class="u-font-size-6 u-spacing-p-l">
        <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
          ADJUST BID ({{ selections.length }} KEYWORD{{
            selections.length > 1 ? `'s` : ''
          }}
          selected)
        </div>
        <radioSelectionWithInput
          class="u-spacing-pt-m"
          :radio-config="bidChangeConfig"
          default-radio-selected="set_new_bid"
          :bid-suggestion="bidSuggestion"
          @onUpdate="onBidUpdates"
        />
      </div>
      <div
        class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
      >
        <footerButtons
          :disable-left-btn="
            !(bidValue && bidValue.inputValue && bidValue.inputValue.length)
          "
          class="u-spacing-ph-l"
          :hollow-hover-button-left="false"
          left-button-type="filled"
          btn-left-text="Apply"
          @onClickBtnRight="closeActionBar"
          @onClickBtnLeft="onSaveBid"
        />
      </div>
    </div>
    <div
      v-if="selections.length && !adjustBids"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
      @widgetParamsChanged="widgetParamsChanged"
      @metadataUpdated="metadataUpdated"
    />
  </div>
</template>
<script>
import {
  deepReplaceObjectValues,
  findDimensionValueFromWidgetParams
} from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import GenericTabsComponent from '@/components/basic/genericTabsComponent.vue';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');
const productTargeting = () =>
  import('@/pages/entity_details/common_components/productTargeting.vue');

export default {
  components: {
    tableWrapper,
    actionsBar,
    radioSelectionWithInput,
    footerButtons,
    productTargeting,
    GenericTabsComponent
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    const context = this;
    return {
      selectedLookBack: 30,
      lookBack: [
        {
          title: '7 days',
          value: 7
        },
        {
          title: '14 days',
          value: 14
        },
        {
          title: '30 days',
          value: 30
        },
        {
          title: '60 days',
          value: 60
        },
        {
          title: '90 days',
          value: 90
        }
      ],
      audienceTargetingKey: '',
      bidValue: null,
      defaultSearchText: '',
      tabList: ['Amazon Audiences', 'View Remarketing', 'Purchase Remarketing'],
      defaultTabIndex: 0,
      showAddCustomProductPanel: false,
      showAddAudiences: false,
      searchDimension: 'ams_cm_asin_search_text_targeting',
      bidChangeConfig: [
        {
          title: 'Set new bid',
          type: 'input',
          value: 'set_new_bid'
        },
        {
          title: 'Increase bid',
          value: 'increase_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease bid',
          value: 'decrease_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ],
      getState: null,
      selections: [],
      adjustBids: null,
      stateChangeRequestPayload: null,
      bidChangeRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      context,
      actionIdMethodMap: {},
      widget: {},
      allowedActions: null,
      bidSuggestion: { show: false },
      tabConfig: null,
      audienceActionPanel: {},
      widgetsMetaData: []
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const obj = {};
      const that = this;
      if (this.tabMetadata?.widgets[0]) {
        const metaDataOfWidget = that.widgetsMetaData?.[0];
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            headerOptions: [
              {
                ui_component: 'rb-button',
                props: {
                  clickFn: that.addCustomProductsButtonClick,
                  text: 'Add Custom Products/Category',
                  type: 'filled'
                },
                class: 'u-spacing-mr-xl'
              }
            ],
            enableDownload: false,
            enableManageColumns: !this.selections.length,
            manageColumnGridPosition: 4,
            headerContainerGridStyles: {
              'grid-template-columns': 'auto auto 1fr',
              display: this.selections.length ? 'none' : 'grid'
            },
            searchParams: {
              defaultSearchText: that.defaultSearchText,
              show: true,
              placeholder: 'Find a product/Category',
              searchDimension:
                metaDataOfWidget?.metadata?.searchKey || this.searchDimension
            },
            rowSelection: {
              mode: 'multiple',
              onSelectionChanged: this.handleSelectionChange
            },
            rowHeight: 70
          }
        };
        if (this.tabConfig?.getHeaderOptions) {
          obj[this.tabMetadata?.widgets[0]] =
            this.tabConfig?.getHeaderOptions(that);
        }
        if (metaDataOfWidget?.metadata?.searchKey) {
          obj[this.tabMetadata?.widgets[0]].props.searchParams.searchDimension =
            metaDataOfWidget.metadata.searchKey;
        }
      }
      return obj;
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.stateChangeRequestPayload = tabConfig?.stateChange?.requestPayload;
    this.bidChangeRequestPayload = tabConfig?.bidChange?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    if (this.$route.params.subEntityType === 'audience_adgroup') {
      this.audienceActionPanel = tabConfig.audiences(this, 'sd');
    }
    this.searchDimension = this.tabConfig?.searchMetric
      ? this.tabConfig?.searchMetric
      : this.searchDimension;

    this.widget = {
      ':widget': this.tabMetadata.widgets[0],
      ':orderByList': [
        {
          dimension: 'ams_cm_targeting_spend',
          direction: 'DESC'
        }
      ],
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInDimensionNameValueList: {
        ':adgroup_id': this.entityId
      },
      replaceEntityInOperationsObject: {
        ':system': `campaign_${this.parentEntityId}`
      }
    };
    this.allowedActions = tabConfig.allowedActions;
  },
  methods: {
    metadataUpdated(data) {
      this.widgetsMetaData = data;
    },
    onSelectLookBack(context, data) {
      if (!data?.length) return;
      this.selectedLookBack = data[0].value;
      this.handleTabsClick(this.tabList[this.defaultTabIndex]);
    },
    handleTabsClick(value) {
      const customActionPanelMap = {
        'Amazon Audiences': {
          value: 'audiences',
          index: 0
        },
        'View Remarketing': {
          value: 'categories',
          index: 1
        },
        'Purchase Remarketing': {
          value: 'categories',
          index: 2
        }
      };
      this.audienceActionPanel = this.tabConfig?.[
        customActionPanelMap[value].value
      ]?.(this, 'sd', value, this.selectedLookBack);
      this.defaultTabIndex = customActionPanelMap[value].index;
      this.audienceTargetingKey = value;
    },
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
      this.checkForSuggestedBid();
    },
    transformBid(oldBid, selection) {
      let delta = parseFloat(this.bidValue.inputValue);
      if (this.bidValue.radioValue === 'set_new_bid') {
        if (this.bidValue.inputValue === 'YY') {
          return parseFloat(oldBid);
        }
        if (this.bidValue.inputValue === 'XX') {
          return parseFloat(selection.data?.ams_cm_targeting_bid_suggested);
        }
        return delta;
      }
      if (this.bidValue?.dropdownValue === 'percentage') {
        delta = oldBid * (delta / 100);
      }
      if (this.bidValue?.radioValue === 'increase_bid') {
        return parseFloat((oldBid + delta).toFixed(2));
      } else {
        return parseFloat((oldBid - delta).toFixed(2));
      }
    },
    onBidUpdates(updatedValues) {
      this.bidValue = updatedValues;
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      if (actionId === 'editStatus') {
        this.callStateChange(dropDownSelection, selections);
      } else {
        if (actionId === 'adjustBids') {
          this.checkForSuggestedBid();
        }
        this[actionId] = true;
      }
    },
    widgetParamsChanged(value) {
      const searchText = findDimensionValueFromWidgetParams(
        value,
        this.tabMetadata?.widgets?.[0],
        this.searchDimension
      );
      this.defaultSearchText = searchText?.dimensionValue || '';
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.adjustBids = null;
      this.bidSuggestion = { show: false };
    },
    callStateChange(dropDownSelection, selections) {
      const payloadArr = [];
      for (const selection of selections) {
        if (
          selection?.data?.ams_cm_adgroup_status === dropDownSelection[0].value
        ) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(this.stateChangeRequestPayload);
          const reqValues = {
            ':campaignId': this.parentEntityId,
            ':adgroupId': this.entityId,
            ':oldState': selection?.data?.ams_cm_targeting_status,
            ':state': dropDownSelection[0].value,
            ':pageUrl': window.location.href,
            ':keywordId': selection?.data?.keyword_id,
            ':keywordText':
              selection.data.ams_cm_targeting_asin_name ||
              selection.data.ams_cm_targeting_category_name,
            ':asin_id': selection.data.ams_cm_targeting_asin_id || 'NA',
            ':adgroupName': this.campaignInfo?.data?.name || 'NA',
            ':matchType': selection?.data?.ams_cm_targeting_match_type || 'NA'
          };
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          payloadArr.push(payload);
        }
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Keyword Status',
          plural: 'Keyword Statuses',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    onSaveBid() {
      const payloadArr = [];
      for (const selection of this.selections) {
        const oldBid = selection?.data?.ams_cm_targeting_base_bid || 0;
        const newBid = this.transformBid(oldBid, selection);
        const payload = cloneDeep(this.bidChangeRequestPayload);
        const reqValues = {
          ':campaignId': this.parentEntityId,
          ':adgroupId': this.entityId,
          ':oldBid': oldBid,
          ':newBid': newBid,
          ':pageUrl': window.location.href,
          ':keywordId': selection?.data?.keyword_id,
          ':keywordText': selection.data.ams_cm_targeting_keyword_text || 'NA',
          ':adgroupName': this.campaignInfo?.data?.name || 'NA',
          ':asin_id': selection.data.ams_cm_targeting_asin_id || 'NA',
          ':matchType': selection?.data?.ams_cm_targeting_match_type || 'NA'
        };

        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        payloadArr.push(payload);
      }
      this.updateMetadataAction(payloadArr, {
        singular: 'Keyword Bid',
        plural: 'Keyword Bids',
        stopFetchApi: true
      });
    },
    callAddTargets(selections) {
      const payloadArr = [];
      for (const selection of selections) {
        const isAudience = selection?.isAudience;
        const marketingType = selection?.marketingType;
        const audienceId = selection?.audienceId;
        const categoryUI = selection?.categoryUI || selection.category;
        let entityType = null;
        let useAudiencePayload = false;
        let marketing = null;
        let newKeywordText = null;
        let dynamicSegment =
          selection?.isDynamicSegment && selection?.dynamicSegment;
        if (marketingType === 'views') {
          entityType = 'View Remarketing';
          marketing = 'views';
          useAudiencePayload = true;
        } else if (marketingType === 'purchase') {
          entityType = 'Purchase Remarketing';
          marketing = 'purchases';
          useAudiencePayload = true;
        } else if (isAudience) {
          entityType = 'Audience';
          marketing = 'audience';
          useAudiencePayload = true;
          newKeywordText = `audienceSameAs=${audienceId}`;
        }
        if (marketing && marketing !== 'audience') {
          const dynamicSegmentText =
            dynamicSegment && `${selection?.dynamicSegment}`;
          newKeywordText = `${marketing}(${
            dynamicSegmentText ||
            (selection?.categoryId && 'asinCategorySameAs=')
          }${dynamicSegmentText ? '' : selection?.categoryId} ${
            selection.lookback && `lookback=${selection.lookback}`
          })`;
        }
        const payload = useAudiencePayload
          ? cloneDeep(this.tabConfig?.addAudiencesToCampaigns)
          : cloneDeep(this.tabConfig?.addTargetsToCampain?.requestPayload);

        const reqValues = {
          ':expressionType':
            dynamicSegment ||
            (isAudience && 'audienceSameAs') ||
            (selection?.categoryId ? 'asinCategorySameAs' : 'asinSameAs'),
          ':entityType':
            dynamicSegment ||
            entityType ||
            (selection?.categoryId ? 'Category' : 'Product'),
          ':campaignId': this.parentEntityId,
          ':marketingType': marketing,
          ':adgroupId': this.entityId,
          ':asin_title':
            audienceId || selection?.categoryId
              ? categoryUI
              : selection.competitor_asin_title,
          ':pageUrl': window.location.href,
          ':image_url': selection.competitor_asin_image_url,
          ':value':
            dynamicSegment ||
            audienceId ||
            (selection?.categoryId
              ? selection.categoryId
              : selection.competitor_asin || 'NA'),
          ':asin_id':
            newKeywordText ||
            (selection?.categoryId
              ? selection.categoryId
              : selection.competitor_asin || 'NA'),
          ':entity_text':
            dynamicSegment || audienceId || selection?.categoryId
              ? categoryUI
              : selection.competitor_asin || 'NA',
          ':adgroupName': this.campaignInfo?.data?.name || 'NA',
          ':bid': selection.new_bid,
          ':product_url': selection.competitor_asin_product_url
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        if (selection.lookback && marketingType) {
          payload.actionPayload.new_expression?.[0]?.value?.push({
            type: 'lookback',
            value: selection.lookback
          });
        }
        if (dynamicSegment) {
          delete payload.actionPayload.new_expression?.[0]?.value?.[0]?.value;
        }
        payloadArr.push(payload);
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Campaign Target',
          plural: 'Campaign Targets',
          action: 'added',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: false,
        entityName
      });
      this.closeActionBar();
      this.showAddCustomProductPanel = false;
    },
    checkForSuggestedBid() {
      this.bidSuggestion = { show: false };
      this.bidValue = null;
      if (
        this.selections.length === 1 &&
        this.selections[0]?.data?.ams_cm_targeting_bid_suggested
      ) {
        this.bidSuggestion = {
          show: true,
          title: 'Amazon Suggested Bid',
          value:
            this.selections[0]?.data?.ams_cm_targeting_bid_suggested.toString(),
          currentBid:
            this.selections[0]?.data?.ams_cm_targeting_base_bid.toString(),
          multipleSuggestions: false
        };
      } else if (this.selections.length > 1) {
        let showBidSuggestion = true;
        this.selections.map((selection, index) => {
          if (!selection.data.ams_cm_targeting_bid_suggested) {
            showBidSuggestion = false;
          }
        });
        this.bidSuggestion = {
          show: showBidSuggestion,
          title: 'Amazon Suggested Bid',
          value: 'XX',
          currentBid: 'YY',
          multipleSuggestions: showBidSuggestion,
          currentBidNote: 'YY - Respective current bid in the table below',
          suggestedBidNote:
            'XX - Respective Amazon suggested bid in the table below'
        };
      } else {
        this.adjustBids = false;
      }
    },
    handleAddAudiences() {
      this.closeActionBar();
      this.showAddAudiences = true;
    },
    addCustomProductsButtonClick() {
      this.closeActionBar();
      this.showAddCustomProductPanel = true;
    },
    reloadComponent(boolReload = true) {
      if (boolReload) {
        this.$store.dispatch(
          this.tabConfig?.apiConfigs?.fetchTableDataComponentKeyGetter
        );
      }
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
.adGroupsKeywordTargetingProduct .inputClassForEntityTaggingSearch {
  display: flex;
  align-items: center;
}
</style>
